import $ from 'jquery';
import 'select2';

document.addEventListener('DOMContentLoaded', function() {
  const $select = $('.select2-tags');
  
  let existingTags = [];
  try {
    // Get the raw value and split by commas
    const rawValue = $select.val() || '';
    existingTags = rawValue.split(',').map(tag => tag.trim()).filter(Boolean);
  } catch (e) {
    console.warn('Failed to parse existing tags:', e);
  }
  
  // Pre-populate existing options with proper formatting
  existingTags.forEach(tag => {
    const option = new Option(tag.trim(), tag.trim(), true, true);
    $select.append(option);
  });

  // Initialize Select2 with existing selections
  $select.val(existingTags).trigger('change');

  $select.select2({
    tags: true,
    multiple: true,
    dropdownParent: $select.parent(),
    ajax: {
      url: '/videos/categories',
      dataType: 'json',
      delay: 250,
      data: function(params) {
        return {
          q: params.term
        };
      },
      processResults: function(data) {
        return {
          results: data.map(function(item) {
            return {
              id: item,
              text: item
            };
          })
        };
      },
      cache: true
    }
  }).on('select2:select', function(e) {
    const data = e.params.data;
    const option = new Option(data.text, data.id, true, true);
    $select.append(option).trigger('change');
  });
  
}); 
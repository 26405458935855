import $ from 'jquery';

export function initPlaylistImport() {
  $('form[action="/videos/fetch_playlist"]').on('submit', function(e) {
    const url = $(this).find('input[name="playlist[url]"]').val();
    const playlistRegex = /^https?:\/\/(www\.)?youtube\.com\/playlist\?list=[a-zA-Z0-9_-]+$/;
    
    if (!playlistRegex.test(url)) {
      e.preventDefault();
      $(this).find('.alert').remove();
      $(this).prepend(`
        <div class="alert alert-danger">
          Please enter a valid YouTube playlist URL (e.g., https://www.youtube.com/playlist?list=...)
        </div>
      `);
      return false;
    }
  });

  $(document).on('ajax:success', 'form[action="/videos/fetch_playlist"]', function(event) {
    const [data] = event.detail;
    const $container = $('#playlist-videos');
    
    const html = `
      <form action="/videos/create_from_playlist" 
            method="post" 
            class="playlist-videos-form"
            data-remote="true">
        <input type="hidden" name="authenticity_token" value="${document.querySelector('meta[name="csrf-token"]').content}">
        ${renderVideosList(data.videos)}
        <div class="form-actions mt-4">
          <input type="hidden" name="playlist_tag" value="${data.tag}">
          <button type="submit" class="btn btn-success">Import Selected Videos</button>
        </div>
      </form>
    `;
    
    $container.html(html);
  });
  
  $(document).on('ajax:error', 'form[action="/videos/fetch_playlist"]', function(event) {
    const [error] = event.detail;
    $('#playlist-videos').html(`
      <div class="alert alert-danger">
        Error fetching playlist: ${error.message}
      </div>
    `);
  });

  $(document).on('ajax:success', 'form[action="/videos/create_from_playlist"]', function(event) {
    const [data, status, xhr] = event.detail;
     
    if (data.message) {
      alert(data.message);
    }
    
    const tag = $('input[name="playlist_tag"]').val();
    
    if (data.redirect_to) {
      window.location.href = data.redirect_to;
    } else {
      window.location.href = `/videos?tag=${encodeURIComponent(tag)}`;
    }
  });

  $(document).on('ajax:error', 'form[action="/videos/create_from_playlist"]', function(event) {
    const [error] = event.detail;
    $('#playlist-videos').prepend(`
      <div class="alert alert-danger">
        Error importing videos: ${error.message}
      </div>
    `);
  });
}

function renderVideosList(videos) {
  return `
    <div class="list-group">
      ${videos.map((video, index) => `
        <div class="list-group-item">
          <div class="form-check">
            <input type="checkbox" 
                   class="form-check-input" 
                   name="video_ids[]" 
                   value="${index}" 
                   id="video_${index}" 
                   checked>
            <input type="hidden" name="videos[${index}][title]" value="${video.title}">
            <input type="hidden" name="videos[${index}][description]" value="${video.description}">
            <input type="hidden" name="videos[${index}][url]" value="${video.url}">
            <input type="hidden" name="videos[${index}][thumbnail]" value="${video.thumbnail}">
            <label class="form-check-label" for="video_${index}">
              <div class="d-flex align-items-center">
                <img src="${video.thumbnail}" class="me-3" style="width: 120px;">
                <div>
                  <h5 class="mb-1">${video.title}</h5>
                  <p class="mb-1 text-muted">${video.url}</p>
                </div>
              </div>
            </label>
          </div>
        </div>
      `).join('')}
    </div>
  `;
} 
import $ from 'jquery'

export function initVideoSearch() {
  const $container = $('.search-container')
  const $input = $container.find('input')
  const $results = $container.find('.search-results')
  let timeoutID = null

  // Handle real-time search
  $input.on('input', function() {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => showSuggestions($(this).val()), 500)
  })

  // Handle enter key and search button
  $input.on('keydown', function(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      performSearch()
    }
  })
  
  $container.find('.search-button').on('click', function(e) {
    e.preventDefault()
    performSearch()
  })

  // Handle remove keyword
  $(document).on('click', '.remove-keyword', function(e) {
    e.preventDefault()
    const $keyword = $(this).closest('.keyword')
    const keyword = $(this).data('keyword')
    const currentSearch = new URLSearchParams(window.location.search)
    const keywords = currentSearch.get('search')?.split(' ').filter(k => k !== keyword) || []
    
    $keyword.remove()
    
    if (keywords.length > 0) {
      window.location.href = `/videos?search=${encodeURIComponent(keywords.join(' '))}`
    } else {
      window.location.href = '/videos'
    }
  })

  async function showSuggestions(query) {
    if (query.length < 2) {
      $results.html('')
      return
    }

    const privateFilter = $container.data('privateFilter')
    console.log('Private filter:', privateFilter);
    const response = await fetch(`/videos/search?q=${encodeURIComponent(query)}&private_filter=${privateFilter}`)
    const results = await response.json()
    
    if (results.length === 0) {
      $results.html('<div class="p-2 text-muted">No results found</div>')
      return
    }

    $results.html(results.map(video => `
      <a href="/videos/${video.slug}" class="search-result d-block p-2">
        <div class="d-flex align-items-center">
          <div class="me-2">
            ${video.thumbnail_attached ? 
              `<img src="${video.thumbnail_url}" class="search-thumbnail" alt="${video.title}">` :
              video.thumbnail_url ? 
                `<img src="${video.thumbnail_url}" class="search-thumbnail" alt="${video.title}">` :
                `<div class="default-thumbnail">
                   <i class="bi bi-play-circle-fill"></i>
                 </div>`
            }
          </div>
          <div>
            <div class="title">${video.title}</div>
            <div class="description small text-muted">${video.description || ''}</div>
            <div class="tags">
              ${video.tags.map(tag => `<span class="badge bg-secondary me-1">${tag}</span>`).join('')}
            </div>
          </div>
        </div>
      </a>
    `).join(''))
  }

  function performSearch() {
    const query = $input.val()
    if (query.length < 2) return
    
    // Show loading state
    $container.addClass('loading')
    
    // Make the search request for the full page
    window.location.href = `/videos?search=${encodeURIComponent(query)}`
  }
} 
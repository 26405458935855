document.addEventListener('DOMContentLoaded', function() {
  const commonConfig = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
    settings: ['quality', 'speed'],
    i18n: {
      qualityLabel: {
        0: 'Auto',
      }
    }
  };

  // Initialize YouTube embeds
  document.querySelectorAll('.plyr__video-embed').forEach(function(player) {
    const enableCaptions = player.dataset.enableCaptions === 'true';
    const config = {
      ...commonConfig,
      youtube: {
        noCookie: false,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        customControls: true,
        quality: { default: 'auto', options: ['auto', 2160, 1440, 1080, 720, 576, 480, 360, 240] }
      }
    };

    if (enableCaptions) {
      config.controls.push('captions');
      config.settings.push('captions');
      config.captions = { active: true, language: 'auto', update: true };
    }

    new Plyr(player, config);
  });
}); 